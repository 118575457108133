import { Race } from '../../models/race'
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { getRaceLiveVideoUrl } from '../../services/get_race_video_url'
import { createRaceState } from '../../services/create_race_state'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { userService } from '../../services/login'
import { getErrorData } from '../../services/get_error'

const testStates = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETE: 'COMPLETE',
}

export const RaceSpeedTest = ({ race, videoSource }: { race: Race; videoSource: string }) => {
    const videoURL = useVideoURL(race, videoSource)
    const { testState, currentStep, startTest } = useRunTest(race, videoSource)
    return (
        <Grid container direction={'column'} spacing={2}>
            {videoURL && (
                <Grid item>
                    <iframe src={videoURL} width={800} height={600} />
                </Grid>
            )}
            {testState === testStates.NOT_STARTED && (
                <Grid item>
                    <Button onClick={startTest} variant={'contained'} color="primary">
                        Start Test
                    </Button>
                </Grid>
            )}
            {testState === testStates.IN_PROGRESS && (
                <>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                    <Grid>
                        <Typography variant={'h1'}>Current step: {currentStep}</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant={'h3'}>Current time: {new Date().toISOString()}</Typography>
                    </Grid>
                </>
            )}
            {testState === testStates.COMPLETE && (
                <Grid item>
                    <Typography variant={'h3'}>Test Complete</Typography>
                </Grid>
            )}
        </Grid>
    )
}

const useRunTest = (race: Race, videoSource: string) => {
    const dispatch = useDispatch()
    const [currentStep, setCurrentStep] = useState(0)
    const [testState, setTestState] = useState(testStates.NOT_STARTED)
    const startTest = () => {
        runTest(race, videoSource, setCurrentStep, setTestState, dispatch)
    }
    useEffect(() => {
        setCurrentStep(0)
        setTestState(testStates.NOT_STARTED)
    }, [race, videoSource])
    return { testState, currentStep, startTest }
}

const runTest = (
    race: Race,
    videoSource: string,
    setCurrentStep: (step: number) => void,
    setTestState: (state: string) => void,
    dispatch: any
) => {
    setTestState(testStates.IN_PROGRESS)
    setCurrentStep(0)
    let step = 0
    const intervalId = setInterval(() => {
        step += 1
        setCurrentStep(step)
        emitStepRaceState(race, videoSource, step, dispatch)
    }, 50)

    setTimeout(() => {
        clearInterval(intervalId)
        createRaceState(
            {
                raceDate: race.raceDate,
                trackCode: race.trackCode,
                raceNumber: race.raceNumber,
                type: 'SPEED_TEST_COMPLETE',
                latencyInSeconds: 0,
                practiceMode: false,
                videoSource: videoSource,
            },
            dispatch
        )
        setTestState(testStates.COMPLETE)
    }, 10_000)
}

const useVideoURL = (race: Race, videoSource: string) => {
    const [videoURL, setVideoURL] = React.useState<string | null>(null)
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchVideo = async () => {
            const video = await getRaceLiveVideoUrl(race.trackCode, videoSource, dispatch)
            if (!video?.url) {
                console.log('No video')
                return
            }
            setVideoURL(video?.url)
        }
        fetchVideo()
    }, [race, videoSource])
    return videoURL
}

const emitStepRaceState = (race: Race, videoSource: string, step: number, dispatch: any) => {
    const uuid = uuidv4()
    axios
        .post(
            `/api/v2/race_state`,
            {
                raceDate: race.raceDate,
                trackCode: race.trackCode,
                raceNumber: race.raceNumber,
                type: `SPEED_TEST_STEP_${step}`,
                uuid: uuid,
                videoSource: videoSource,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 10,
            }
        )
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                getErrorData(error, dispatch)
            }
        })
}
