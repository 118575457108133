import {
    AppBar,
    Badge,
    Collapse,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    BrightnessHigh,
    BrightnessLow,
    Check,
    ChevronLeft,
    ChevronRight,
    ExpandLess,
    ExpandMore,
    Feedback,
    History,
    Menu,
    RateReview,
    School,
    Timer,
    TrendingUp,
    DoneAll,
    Visibility,
    Speed,
} from '@material-ui/icons'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { push } from 'connected-react-router'
import { getDarkMode } from '../../reducers/get_dark_mode'
import { userService } from '../../services/login'
import { getMe } from '../../reducers/get_me'

export const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    darkModeButton: {
        marginLeft: 'auto',
    },
    subList: {
        marginLeft: theme.spacing(4),
    },
    toggleButton: {
        marginLeft: theme.spacing(1),
    },
    logoutInfo: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
}))

export const NavBar = ({
    drawerOpen,
    setDrawerOpen,
}: {
    drawerOpen: boolean
    setDrawerOpen: (open: boolean) => void
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const {
        userName,
        team,
        darkMode,
        historicalTrackers,
        realTimeTrackers,
        actionReviews,
        betaReleaseChannel,
        numLearnings,
    } = useSelector((state: RootStateType) => ({
        userName: state?.meStore?.me?.userName,
        team: state?.meStore?.me?.team,
        betaReleaseChannel: state?.meStore?.me?.betaReleaseChannel,
        darkMode: state?.darkModeStore?.darkMode ?? false,
        historicalTrackers: state?.historicalTrackersStore?.historicalTrackers,
        realTimeTrackers: state?.realTimeTrackersStore?.realTimeTrackers,
        actionReviews: state?.actionReviewsStore?.actionReviews,
        numLearnings: state?.userActionCommentsStore?.userActionComments?.filter((c) => !c.Acknowledged)?.length ?? 0,
    }))
    const dispatch = useDispatch()
    const [historicalTrackingOpen, setHistoricalTrackingOpen] = useState(true)
    const [realTimeTrackingOpen, setRealTimeTrackingOpen] = useState(true)
    const [actionReviewsOpen, setActionReviewsOpen] = useState(false)
    const [learningCenterOpen, setLearningCenterOpen] = React.useState(false)
    const [quizzesOpen, setQuizzesOpen] = React.useState(false)

    const isGrader =
        team === 'admin' || historicalTrackers?.some((m) => m.IsGrader) || realTimeTrackers?.some((m) => m.IsGrader)
    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(true)}
                        edge="start"
                        className={clsx(classes.menuButton, 'nav-menu', drawerOpen && classes.hide)}
                        data-testid={'nav-menu'}
                    >
                        <Badge
                            color={'secondary'}
                            badgeContent={numLearnings}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className={'.learning-center-flag'}
                        >
                            <Menu />
                        </Badge>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Live Data Entry
                    </Typography>
                    {betaReleaseChannel && (
                        <Typography variant={'body1'} className={classes.toggleButton}>
                            Beta Release
                        </Typography>
                    )}
                    <IconButton
                        onClick={() => {
                            const newValue = !darkMode
                            localStorage.setItem('darkMode', newValue ? 'true' : 'false')
                            dispatch(getDarkMode(!darkMode))
                        }}
                        color="inherit"
                        edge="end"
                        className={classes.darkModeButton}
                    >
                        <Tooltip title={'toggle dark/light mode'}>
                            <span>
                                {darkMode && <BrightnessHigh />}
                                {!darkMode && <BrightnessLow />}
                            </span>
                        </Tooltip>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => setDrawerOpen(false)} className={'nav-menu-close'}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider />
                <Grid container direction={'column'}>
                    <Grid item>
                        {userName && realTimeTrackers && realTimeTrackers.length > 0 && (
                            <>
                                <List>
                                    <ListItem button onClick={() => setRealTimeTrackingOpen(!realTimeTrackingOpen)}>
                                        <ListItemIcon>
                                            <Timer />
                                        </ListItemIcon>
                                        <ListItemText primary={'Real Time Tracking Modules'} />
                                        {realTimeTrackingOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={realTimeTrackingOpen} timeout="auto" unmountOnExit>
                                        {realTimeTrackingOpen &&
                                            realTimeTrackers.map((t) => {
                                                return (
                                                    <ListItem
                                                        button
                                                        key={t.Name}
                                                        className={classes.subList}
                                                        onClick={() =>
                                                            dispatch(
                                                                push(
                                                                    `/generic_real_time_tracker/${t.Name.split(
                                                                        ' '
                                                                    ).join('_')}`
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <ListItemText primary={t.Name} />
                                                    </ListItem>
                                                )
                                            })}
                                    </Collapse>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && historicalTrackers && historicalTrackers.length > 0 && (
                            <>
                                <List>
                                    <ListItem button onClick={() => setHistoricalTrackingOpen(!historicalTrackingOpen)}>
                                        <ListItemIcon>
                                            <History />
                                        </ListItemIcon>
                                        <ListItemText primary={'Historical Tracking Modules'} />
                                        {historicalTrackingOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={historicalTrackingOpen} timeout="auto" unmountOnExit>
                                        {historicalTrackingOpen &&
                                            historicalTrackers.map((t) => {
                                                return (
                                                    <ListItem
                                                        button
                                                        key={t.Name}
                                                        className={classes.subList}
                                                        onClick={() =>
                                                            dispatch(
                                                                push(
                                                                    `/generic_historical_tracker/${t.Name.split(
                                                                        ' '
                                                                    ).join('_')}`
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <ListItemText primary={t.Name} />
                                                    </ListItem>
                                                )
                                            })}
                                    </Collapse>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && actionReviews && actionReviews.length > 0 && (
                            <>
                                <List>
                                    <ListItem button onClick={() => setActionReviewsOpen(!actionReviewsOpen)}>
                                        <ListItemIcon>
                                            <Visibility />
                                        </ListItemIcon>
                                        <ListItemText primary={'Action Review Modules'} />
                                        {actionReviewsOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={actionReviewsOpen} timeout="auto" unmountOnExit>
                                        {actionReviewsOpen &&
                                            actionReviews.map((r) => {
                                                return (
                                                    <ListItem
                                                        button
                                                        key={r.Name}
                                                        className={classes.subList}
                                                        onClick={() => dispatch(push(`/action_review/${r.ID}`))}
                                                    >
                                                        <ListItemText primary={r.Name} />
                                                    </ListItem>
                                                )
                                            })}
                                    </Collapse>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && (
                            <>
                                <List>
                                    <ListItem button onClick={() => setLearningCenterOpen(!learningCenterOpen)}>
                                        <ListItemIcon>
                                            <School />
                                        </ListItemIcon>
                                        <ListItemText primary={'Learning Guides'} />
                                        {learningCenterOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={learningCenterOpen} timeout="auto" unmountOnExit>
                                        {historicalTrackers &&
                                            historicalTrackers
                                                ?.filter((r) => r.GuideURL && r.GuideURL.length > 0)
                                                ?.map((h) => (
                                                    <ListItem
                                                        button
                                                        key={h.Name}
                                                        className={classes.subList}
                                                        onClick={() =>
                                                            dispatch(
                                                                push(
                                                                    `/learning_center_guide/${h.Name.split(' ').join(
                                                                        '_'
                                                                    )}`
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <ListItemText primary={`${h.Name} guide`} />
                                                    </ListItem>
                                                ))}
                                        {realTimeTrackers &&
                                            realTimeTrackers
                                                ?.filter((r) => r.GuideURL && r.GuideURL.length > 0)
                                                ?.map((r) => (
                                                    <ListItem
                                                        button
                                                        key={r.Name}
                                                        className={classes.subList}
                                                        onClick={() =>
                                                            dispatch(
                                                                push(
                                                                    `/learning_center_guide/${r.Name.split(' ').join(
                                                                        '_'
                                                                    )}`
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <ListItemText primary={`${r.Name} guide`} />
                                                    </ListItem>
                                                ))}
                                    </Collapse>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && (
                            <>
                                <List>
                                    <ListItem button onClick={() => setQuizzesOpen(!quizzesOpen)}>
                                        <ListItemIcon>
                                            <DoneAll />
                                        </ListItemIcon>
                                        <ListItemText primary={'Quizzes'} />
                                        {learningCenterOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={quizzesOpen} timeout="auto" unmountOnExit>
                                        {historicalTrackers?.map((h) => (
                                            <ListItem
                                                button
                                                key={h.Name}
                                                className={classes.subList}
                                                onClick={() =>
                                                    dispatch(push(`/quizzes/${h.Name.split(' ').join('_')}`))
                                                }
                                            >
                                                <ListItemText primary={`${h.Name}`} />
                                            </ListItem>
                                        ))}
                                    </Collapse>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && (
                            <>
                                <List>
                                    <ListItem button onClick={() => dispatch(push('/learning_center_feedback'))}>
                                        <ListItemIcon>
                                            <Badge
                                                color={'secondary'}
                                                badgeContent={numLearnings}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                className={'.learning-center-flag'}
                                            >
                                                <Feedback />
                                            </Badge>
                                        </ListItemIcon>
                                        <ListItemText primary="Feedback" />
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {userName && (
                            <>
                                <List>
                                    <ListItem button onClick={() => dispatch(push('/performance'))}>
                                        <ListItemIcon>
                                            <TrendingUp />
                                        </ListItemIcon>
                                        <ListItemText primary={'My Performance'} />
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {isGrader && (
                            <>
                                <List>
                                    <ListItem button onClick={() => dispatch(push('/objections'))}>
                                        <ListItemIcon>
                                            <RateReview />
                                        </ListItemIcon>
                                        <ListItemText primary={'Objection Review'} />
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {team === 'admin' && (
                            <>
                                <List>
                                    <ListItem button onClick={() => dispatch(push('/generic_action_review'))}>
                                        <ListItemIcon>
                                            <RateReview />
                                        </ListItemIcon>
                                        <ListItemText primary={'Generic Action Review'} />
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {team === 'admin' && (
                            <>
                                <List>
                                    <ListItem button onClick={() => dispatch(push('/quiz_adjudication'))}>
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                        <ListItemText primary={'Quiz Adjudication'} />
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        )}
                    </Grid>
                    <Grid>
                        <>
                            <List>
                                <ListItem button onClick={() => dispatch(push('/speed_test'))}>
                                    <ListItemIcon>
                                        <Speed />
                                    </ListItemIcon>
                                    <ListItemText primary={'Speed test'} />
                                </ListItem>
                            </List>
                            <Divider />
                        </>
                    </Grid>
                    <Grid item>
                        <List>
                            <ListItem>
                                <ListItemText primary={userName} />
                            </ListItem>
                            {userName && (
                                <>
                                    <ListItem>
                                        <Link
                                            color={'inherit'}
                                            underline={'none'}
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSde-uIflc8Yfj6CrPAxKiHC_mBaWCCNiEr7JbcprXnMyU82bw/viewform?usp=sf_link"
                                            target={'_blank'}
                                        >
                                            <ListItemText primary={'Give Feedback'} />
                                        </Link>
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={'log-out'}
                                        onClick={() => {
                                            userService.logout()
                                            dispatch(push('/login'))
                                            dispatch(getMe({}))
                                        }}
                                    >
                                        <ListItemText primary={'Logout'} />
                                    </ListItem>
                                </>
                            )}
                            {!userName && (
                                <ListItem
                                    button
                                    onClick={() => {
                                        userService.logout()
                                        dispatch(getMe({}))
                                        dispatch(push('/login'))
                                    }}
                                >
                                    <ListItemText primary={'Login'} />
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    )
}
